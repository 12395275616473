import { Component, createResource } from 'solid-js'
import Button from '~/components/button/button'
import TextField from '~/components/fields/text/text'
import styles from '~/components/forms/styles/complex-form.module.scss'
import { createSeries } from '~/services/database/series'
import { useNavigate } from '@solidjs/router'
import { SubmitHandler, createForm, setResponse, valiForm } from '@modular-forms/solid'
import { SeriesCreateFormType, SeriesCreateSchema } from '~/components/forms/series-create/series-create.interface'
import MultiSelectField from '~/components/fields/multi-select/multi-select'
import { getContacts } from '~/services/database/contacts'
import { SelectEntry } from '~/components/fields/multi-select/multi-select.interfaces'
import { Permission } from '~/types/database/series'
import { translations } from '~/translations'
import FormError from '~/components/form-error/form-error'
import { user } from '~/services/database/users'

const SeriesCreateForm: Component = () => {
  const navigate = useNavigate()
  const [form, { Form, Field }] = createForm<SeriesCreateFormType>({
    initialValues: {
      translators: [],
      proofreaders: [],
      typesetters: []
    },
    validate: valiForm(SeriesCreateSchema)
  })
  
  const onSubmit: SubmitHandler<SeriesCreateFormType> = async (values) => {
    const atLeastOneRole =
      values.translators.length > 0
      || values.proofreaders.length > 0
      || values.typesetters.length > 0

    if (!atLeastOneRole){
      setResponse(form, {
        status: 'error',
        message: translations().series.forms.create.errors.noRolesAttributed
      })
      return;
    }

    const permissions: Permission[] = []
    const translatorsPermissions: Permission[] = values.translators.map(entry => ({ userId: entry, permission: 'translation' }))
    const proofreadersPermissions: Permission[] = values.proofreaders.map(entry => ({ userId: entry, permission: 'proofreading' }))
    const typesettersPermissions: Permission[] = values.typesetters.map(entry => ({ userId: entry, permission: 'typesetting' }))
    permissions.push(...translatorsPermissions, ...proofreadersPermissions, ...typesettersPermissions)

    await createSeries({
      ...values,
      permissions
    }).then((result) => {
      if(result){
        navigate(`/series/${result.id}`)
      }
    })
  }

  const [contacts] = createResource(async () => {
    const contacts = await getContacts()
    const selectOptions: SelectEntry[] = contacts.map(entry => ({
      label: entry.meta?.name ?? `User ${entry.contact_id}`,
      value: entry.contact_id.toString()
    }))

    // Add self
    const userData = user()
    if (userData && userData.id){
      selectOptions.push({
        label: userData.name ?? `User ${userData.id}`,
        value: userData.id
      })
    }

    return selectOptions
  }, {
    initialValue: []
  })

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          {translations().series.forms.create.title}
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="client">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.create.client}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="title">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.create.seriesTitle}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="author">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.create.author}
                  {...field}
                  {...props}
                />
              )}
            </Field>

            <FormError response={form.response} />

            <Button
              type='submit'
              style='lagoon'
              isLoading={form.submitting}
            >
              {translations().general.actions.create}
            </Button>
          </div>
        </div>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="translators" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.translation}
                  options={contacts.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="proofreaders" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.proofreading}
                  options={contacts.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="typesetters" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.typesetting}
                  options={contacts.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SeriesCreateForm