import SeriesCreateForm from '~/components/forms/series-create/series-create'
import Header from '~/components/header/header'

export default function ChapterList() {
  return (
    <>
      <Header />
      <SeriesCreateForm />
    </>
  )
}
