import { InferOutput, array, nonEmpty, object, pipe, string } from 'valibot'

export const SeriesCreateSchema = object({
  client: pipe(string(), nonEmpty()),
  title: pipe(string(), nonEmpty()),
  author: string(),
  translators: array(string()),
  proofreaders: array(string()),
  typesetters: array(string()),
})

export type SeriesCreateFormType = InferOutput<typeof SeriesCreateSchema>